import Vue from 'vue';
import nationalities from '@/data/nationalities';
import differenceInYears from 'date-fns/differenceInYears';
import weekDays from '@/data/weekDays';
import months from '@/data/months';
import parseISO from 'date-fns/parseISO';
import { toFixedTwoDigitsMax } from '@/utils/misc';
import { ceilMod } from '@/utils/maths';
import format from 'date-fns/format';
import esLocale from 'date-fns/locale/es';

// nationalityId must be an integer
Vue.filter('toNationalityName', nationalityId => {
  return nationalityId ? nationalities[nationalityId] : '';
});

// dateOfBirth must be a valid date format string
Vue.filter('toAge', dateOfBirth => {
  return dateOfBirth ? differenceInYears(new Date(), new Date(dateOfBirth)) : '';
});

// date must be a valid date format string
Vue.filter('toDayOfWeek', date => {
  return weekDays[parseISO(date).getDay()];
});

// date must be a valid date format string
Vue.filter('toDayOfMonth', date => {
  return parseISO(date).getDate();
});

// date must be a valid date format string
Vue.filter('toMonth', date => {
  return months[parseISO(date).getMonth()];
});

// Time string must be in format hh:mm:ss 19:00:00
Vue.filter('removeSecondsFromTime', time => {
  const timeArr = time.split(':');
  timeArr.pop();
  return timeArr.join(':');
});

// Game status must be a integer, current options are 2 = postponed and 3 = canceled
Vue.filter('toGameStatusString', gameStatus => {
  return gameStatus === 2 ? 'pospuesto' : gameStatus === 3 ? 'cancelado' : '';
});

// Number must be a number type
Vue.filter('toFixed2', toFixedTwoDigitsMax);

// Should be decimal, rounds 2 values
Vue.filter('fixed', value => {
  const isFloat = x => {
    return !!(x % 1);
  };
  if (isFloat(value)) {
    return value.toFixed(2);
  } else {
    return value;
  }
});

Vue.filter('ceilMod', value => {
  return ceilMod(value, 4);
});

// value must be an string or number
Vue.filter('toCurrencyString', value => {
  const valueString = (value || 0).toString();
  const [integerString, decimalString] = valueString.split('.');
  let currencyString = '';
  for (let i = 1; i <= integerString.length; i++) {
    currencyString = `${i !== integerString.length && i % 3 === 0 ? ',' : ''}${valueString.charAt(
      integerString.length - i,
    )}${currencyString}`;
  }
  return `${currencyString}${decimalString ? `.${decimalString}` : ''}`;
});

Vue.filter('toFormattedDate', (date, dateFormat, avoidTimeZone = false) => {
  if (date) {
    let dateAux = new Date(date);
    if (avoidTimeZone) {
      const userTimezoneOffset = dateAux.getTimezoneOffset() * 60000;
      dateAux = new Date(dateAux.getTime() + userTimezoneOffset);
    }
    return format(dateAux, dateFormat, { locale: esLocale });
  }
  return date;
});
