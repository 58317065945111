export default {
  2: 'Afgano',
  3: 'Albanés',
  4: 'Argelino',
  6: 'Andorrano',
  7: 'Angoleño',
  8: 'Anguillan',
  9: 'Antiguan',
  10: 'Argentino',
  11: 'Armenio',
  12: 'Arubeño',
  13: 'Australiano',
  14: 'Austriaco',
  15: 'Azerbaiyano',
  16: 'Bahameño',
  17: 'Bareiní',
  18: 'Bangladesí',
  19: 'Barbadense',
  20: 'Bielorruso',
  21: 'Belga',
  22: 'Beliceño',
  23: 'Beninés',
  24: 'Bermudeño',
  25: 'Butanés',
  26: 'Boliviano',
  27: 'Bosnio',
  28: 'Botsuano',
  29: 'Brasileño',
  30: 'Islas Vírgenes Británicas',
  31: 'Bruneano',
  32: 'Bulgaro',
  33: 'Burkinés',
  34: 'Birmano',
  35: 'Burundés',
  36: 'Camboyano',
  37: 'Cameroonian',
  38: 'Canadiense',
  39: 'Caboverdiano',
  40: 'Caimanés',
  41: 'Centro Africano',
  42: 'Chadiano',
  43: 'Chileno',
  44: 'Chino',
  45: 'Isla de Navidad',
  46: 'Cocos Island',
  47: 'Colombiano',
  48: 'Comoriense',
  49: 'Congoleño',
  50: 'Cookiano',
  51: 'Costarriqueño',
  52: 'Marfileño',
  53: 'Croata',
  54: 'Cubano',
  55: 'Chipriota',
  56: 'Checo',
  57: 'Danés',
  58: 'Yibutiano',
  59: 'Dominicano',
  60: 'Dominicano',
  61: 'Timorense',
  62: 'Ecuatoriano',
  63: 'Egipcio',
  64: 'Salvadoreño',
  65: 'Inglés',
  66: 'Ecuatoguineano',
  67: 'Eritreo',
  68: 'Estonio',
  69: 'Etíope',
  70: 'Islas Malvinas',
  71: 'Feroés',
  72: 'Fiyiano',
  73: 'Finés',
  74: 'Francés',
  75: 'Guayana Francesa',
  76: 'Francopolinecio',
  77: 'Gabonés',
  78: 'Gambiano',
  79: 'Georgiano',
  80: 'Alemán',
  81: 'Ghanés',
  82: 'Gibraltareño',
  83: 'Británico',
  84: 'Griego',
  85: 'Groenlandés',
  86: 'Granadino',
  87: 'Guadeloupeño',
  88: 'Guatemalteco',
  89: 'Guatemalteco',
  90: 'Guineano',
  91: 'Guyanés',
  92: 'Haitiano',
  93: 'Hondureño',
  94: 'Hongkongese',
  95: 'Húngaro',
  96: 'Islandés',
  97: 'Indio',
  98: 'Indonesio',
  99: 'Iraní',
  100: 'Iraquí',
  101: 'Irlandés',
  102: 'Manés',
  103: 'Israelí',
  104: 'Italiano',
  105: 'Jamaiquino',
  106: 'Japonés',
  107: 'Jordano',
  108: 'Kazajo',
  109: 'Keniano',
  110: 'Kiribatiano',
  111: 'Norcoreano',
  112: 'Surcoreano',
  113: 'Kosovan',
  114: 'Kuwaití',
  115: 'Kirguis',
  116: 'Laosiano',
  117: 'Letón',
  118: 'Libanés',
  119: 'Basotho',
  120: 'Liberiano',
  121: 'Libio',
  122: 'Liechtenstein',
  123: 'Lituano',
  124: 'Luxemburgués',
  125: 'Macaneso',
  126: 'Macedónio',
  127: 'Madagascarí',
  128: 'Malawi',
  129: 'Malasio',
  130: 'Maldivo',
  131: 'Maliense',
  132: 'Maltés',
  133: 'Marshallese',
  134: 'Martinicano',
  135: 'Mauritano',
  136: 'Mauriciano',
  137: 'Mahoran',
  138: 'Mexicano',
  139: 'Micronesio',
  140: 'Moldavo',
  141: 'Monacan',
  142: 'Mongol',
  143: 'Montenegrino',
  144: 'Montserratiano',
  145: 'Marroquí',
  146: 'Pozambiqueño',
  147: 'Namibio',
  148: 'Nauruan',
  149: 'Nepalí',
  150: 'Dolandés',
  151: 'Neocaledonio',
  152: 'Neozelandés',
  153: 'Nicaragüense',
  154: 'Niueño',
  155: 'Nigerino',
  156: 'Nigeriano',
  157: 'Noruego',
  158: 'Normariano',
  159: 'Omaní',
  160: 'Pakistaní',
  161: 'Palestino',
  162: 'Palauan',
  163: 'Panameño',
  164: 'Papú',
  165: 'Paraguayo',
  166: 'Peruano',
  167: 'Filipino',
  168: 'Pitcairnés',
  169: 'Polaco',
  170: 'Portugués',
  171: 'Puerto Riqueño',
  172: 'Catarí',
  173: 'Reunionés',
  174: 'Rumano',
  175: 'Ruso',
  176: 'Ruandés',
  177: 'Santa Elena',
  178: 'Sancristobaleño',
  179: 'Santa Lucía',
  180: 'Miquelonnais',
  181: 'Vicenciano',
  182: 'Samoano',
  183: 'Sanmariense',
  184: 'Santotomense',
  185: 'Saudí',
  186: 'Escocés',
  187: 'Senegalés',
  188: 'Serbio',
  189: 'Seychellense',
  190: 'Sierraleonés',
  191: 'Singapurense',
  192: 'Eslovaco',
  193: 'Esloveno',
  194: 'Salomonense',
  195: 'Somalí',
  196: 'Surafrincano',
  197: 'Surosetio',
  198: 'Español',
  199: 'Esrilanques',
  200: 'Sudanés',
  201: 'Surinamés',
  202: 'Swazi',
  203: 'Sueco',
  204: 'Suizo',
  205: 'Sirio',
  206: 'Taiwanés',
  207: 'Tayiko',
  208: 'Tanzano',
  209: 'Tailandés',
  210: 'Togolés',
  211: 'Tongano',
  212: 'Trinitense',
  213: 'Tunecino',
  214: 'Turco',
  215: 'Turcomano',
  216: 'none',
  217: 'Tuvaluano',
  218: 'Ugandés',
  219: 'Ucranio',
  220: 'Emiratí',
  221: 'Británico',
  222: 'Americano',
  223: 'Uruguayo',
  224: 'Uzbeko',
  225: 'Vanuatuense',
  226: 'Venezolano',
  227: 'Vietnamita',
  228: 'Islas Vírgenes',
  229: 'Galés',
  230: 'Walisiano',
  231: 'Saharaui',
  232: 'Yemenita',
  233: 'Zambiano',
  234: 'Zimbabuo',
  236: 'Curazoleño',
  237: 'San Martín',
  238: 'Afgano',
  239: 'Debutantes',
};
